import React from "react";
import Image from "next/image";
import styles from "./Events.module.scss"; // Assuming you've created a CSS module for styling
import stylesBanner from "./BannerSmall.module.scss";
import {
  generateGradientTextStyle,
  generateContentGridItemHeadingStyle,
} from "./util/theme";

const BannerSmall = ({ data, theme }) => {
  const firstTwoCards = data.content.filter(
    (item) => item.type === "image-first" || item.type === "title-first"
  );
  const biggerCards = data.content
    .filter((item) => item.type === "bigger-card")
    .slice(0, 2);
  return (
    <div
      className={`${stylesBanner["banner-small"]}`}
      style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`${stylesBanner["container-xl-low-padding"]}`}>
        <div className={`${stylesBanner["banner-small-content"]}`}>
          <h2
            className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
          >
            {data.title.map((item) => (
              <span
                key={item.id}
                className={`${item.className ? styles[item.className] : ""} ${theme.style === "light" ? styles["text-black-color"] : ""}`}
                style={
                  !item.className
                    ? generateGradientTextStyle(
                        theme.gradient1,
                        theme.gradient2
                      )
                    : {}
                }
              >
                {item.text}
              </span>
            ))}
          </h2>
          <div className={stylesBanner["banner-small-content-grid"]}>
            <div className={stylesBanner["first-two-cards"]}>
              {firstTwoCards.map((item) => (
                <div key={item.id}>
                  <div>
                    <div className={`${stylesBanner["custom-card"]} ${theme.style === "light" ? stylesBanner["stats-card-tiles-light"] : ""}`}>
                      <div className="card-body text-center">
                        {item.type === "image-first" ? (
                          <>
                            <h3
                              className={
                                stylesBanner[
                                  "banner-small-content-grid-item-heading"
                                ]
                              }
                              style={generateContentGridItemHeadingStyle(
                                theme.gradient1,
                                theme.gradient2
                              )}
                            >
                              <span>{item.heading}</span>
                            </h3>
                            <img
                              src={item.images[0]}
                              loading="lazy"
                              alt=""
                              className="image-10"
                            />

                            {item.title && (
                              <p
                                className={`${stylesBanner["banner-small-content-grid-item-pr"]} ${theme.style === "light" ? stylesBanner["text-dark-colors"] : stylesBanner["text-light-colors"]}`}
                              >
                                {item.title}
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <h3
                              className={
                                stylesBanner[
                                  "banner-small-content-grid-item-heading"
                                ]
                              }
                              style={generateContentGridItemHeadingStyle(
                                theme.gradient1,
                                theme.gradient2
                              )}
                            >
                              <span>{item.heading}</span>
                            </h3>
                            {item.title && (
                              <p
                                className={`${stylesBanner["banner-small-content-grid-item-pr"]} ${theme.style === "light" ? stylesBanner["text-dark-colors"] : stylesBanner["text-light-colors"]}`}
                              >
                                {item.title}
                              </p>
                            )}
                            {item.images.map((imgSrc, imgIndex) => (
                              <img
                                key={imgIndex}
                                src={imgSrc}
                                loading="lazy"
                                alt=""
                                className={`mt-16 _${imgIndex + 1}`}
                              />
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              {biggerCards.length > 0 && (
                <div className={stylesBanner["bigger-cards-container"]}>
                  {biggerCards.map((item) => (
                    <div
                      key={item.id}
                      className={`${stylesBanner["custom-card"]} ${theme.style === "light" ? stylesBanner["stats-card-tiles-light"] : ""}`}
                    >
                      <div className="card-body text-center">
                        <h3
                          className={
                            stylesBanner[
                              "banner-small-content-grid-item-heading"
                            ]
                          }
                          style={generateContentGridItemHeadingStyle(
                            theme.gradient1,
                            theme.gradient2
                          )}
                        >
                          <span>{item.heading}</span> {item.title}
                        </h3>
                        <div className="div-block-39">
                          {item.images.map((imgSrc, imgIndex) => (
                            <img
                              key={imgIndex}
                              src={imgSrc}
                              loading="lazy"
                              alt=""
                              className={`mt-16 _${imgIndex + 1}`}
                              style={{ width: '54px', height: '54px' }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSmall;
